import axios from "axios";
import React from "react";
import { useState } from "react";
import TypeWriter from "react-typewriter";
import ReactJson from "react-json-view";
import { useLocation } from "react-router";
import Code from "./Code";
import Logo from "../images/logo.png";
import JSONInput from "react-json-editor-ajrm";
import { useEffect } from "react";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

const removeStartSlash = (str) => {
  if (str && str[0] === "/") {
    return removeStartSlash(str.substr(1));
  }
  return str;
};

function Workflow() {
  const [data, setData] = useState(null);

  const [tempData, setTempData] = useState([]);

  const [loginUrl, setLoginUrl] = useState("");

  const [jsonError, setJsonError] = useState(false);

  const [stepByStep, setStepByStep] = useState();

  // Get data from query or session
  let query = useQuery();
  let loginKey = query.get("loginKey");
  let loginToken = query.get("loginToken");

  useEffect(() => {
    setStepByStep(JSON.parse(query.get("stepByStep") || sessionStorage.getItem("stepByStep") || false));
  }, []);

  const [textSequence, setTextSequence] = useState(loginToken && loginKey ? 5 : stepByStep ? 0 : 1);

  const [codeTextVisible, setCodeTextVisible] = useState(stepByStep ? true : false);

  useEffect(() => {
    setCodeTextVisible(stepByStep);
    sessionStorage.setItem("stepByStep", stepByStep);
    setTextSequence(loginToken && loginKey ? 5 : stepByStep ? 0 : 1);
  }, [stepByStep]);

  const [codeVisible, setCodeVisible] = useState(false);

  let integrationPrefix = query.get("integrationPrefix") || sessionStorage.getItem("integrationPrefix");
  if (integrationPrefix) {
    sessionStorage.setItem("integrationPrefix", integrationPrefix);
  }

  let endpoint = sessionStorage.getItem("endpoint") || "";
  let integrationUrl = query.get("integrationUrl") || sessionStorage.getItem("integrationUrl");
  if (integrationUrl) {
    let urlAndParams = integrationUrl.split(/\?(.+)/)
    let splitedUrl = urlAndParams[0].split("api/v1/trigger/");
    let integrationId = splitedUrl[1].split("/")[0];
    endpoint = decodeURIComponent(removeStartSlash(`${splitedUrl[1].replace(integrationId, "")}?${urlAndParams.length > 1 ? urlAndParams[1] : ""}`));

    integrationPrefix = `${splitedUrl[0]}api/v1/trigger/${integrationId}/`;
    sessionStorage.setItem("integrationPrefix", integrationPrefix);
    sessionStorage.setItem("integrationId", integrationId);
    sessionStorage.setItem("endpoint", endpoint);

    sessionStorage.setItem("integrationUrl", integrationUrl);
  } else if (textSequence !== 4) {
    setTempData("Integration Url not available");
    setTextSequence(4);
  }

  const [reqEndpoint, setReqEndpoint] = useState(endpoint);

  let method = query.get("method") || sessionStorage.getItem("method") || "GET";
  if (method) {
    sessionStorage.setItem("method", method);
  }

  const [reqMethod, setReqMethod] = useState(method);

  let scope = query.get("scope") || sessionStorage.getItem("scope") || "";
  if (scope) {
    sessionStorage.setItem("scope", scope);
  }
  const [reqScope, setReqScope] = useState(scope);
  const [integrationData, setIntegrationData] = useState({});
  const [reqData, setReqData] = useState();

  useEffect(() => {
    setIntegrationData(JSON.parse(query.get("integrationData") || sessionStorage.getItem("integrationData") || "{}"));
    setReqData(JSON.parse(query.get("integrationData") || sessionStorage.getItem("integrationData") || "{}"));
    if (integrationData) {
      sessionStorage.setItem("integrationData", JSON.stringify(integrationData));
    }
  }, []);

  // Get available tokens

  const hitRequest = () => {
    const theCodeMeshTokens = JSON.parse(sessionStorage.getItem("theCodeMeshTokens", {}));
    const separator = integrationUrl.indexOf("?") === -1 ? "?" : "&";
    const redirect_url = "https://playaround.thecodemesh.com/workflow/";
    if (integrationUrl.indexOf("redirect_url=") > -1) {
      updateQueryStringParameter(integrationUrl, "redirect_url", redirect_url);
    } else {
      integrationUrl = `${integrationUrl}${separator}redirect_url=${redirect_url}`;
    }
    const data = method === "GET" || method === "DELETE" ? {} : { ...integrationData };
    axios({
      method: method,
      url: integrationUrl,
      data: data,
      headers: theCodeMeshTokens,
    })
      .then((response) => {
        setTempData(response.data);
        setTextSequence(2);
      })
      .catch((error) => {
        //handle generic errors
        if (!error.response || Math.floor(error.status / 100) === 5) {
          setTempData("Servers not reachable");
          setTextSequence(4);
          return error;
        }
        if (error.response.data.code === 400 || error.response.data.code === 401) {
          const scopeKey = error.response.data.message.scopeKey;
          let loginUrl = error.response.data.message.loginUrl;
          if (scopeKey && scope) {
            //update with required scope here
            loginUrl = updateQueryStringParameter(loginUrl, scopeKey, scope);
          }
          if (loginUrl) {
            setLoginUrl(loginUrl);
            setTextSequence(3);
            // window.open(loginUrl, "_blank");
          } else {
            //silent fail
            console.log("TheCodeMesh integration failed, trace : ", error.response.data.message.trace);
            setTempData(error.response.data.message.trace);
            setTextSequence(4);
          }
        } else {
          setTempData(JSON.stringify(error.response.data));
          setTextSequence(4);
        }
      });
  };

  let waitTime = 2000; //2sec
  let timeout_id;
  document.body.addEventListener("mousedown", function (e) {
    //set interval when mouse is held down; it will increase time_passed by 1 every 1000ms(1s) and
    //append it to a div to show curr time passed
    timeout_id = setInterval(() => {
      waitTime = waitTime + 100;
    }, 100);
  });

  document.body.addEventListener("mouseup", function (e) {
    //button was released; clear interval that's been adding to time_passed
    clearInterval(timeout_id);
  });

  // Checks if we have loginToken and Window object
  if (loginToken && loginKey && window && sessionStorage) {
    const theCodeMeshTokens = JSON.parse(sessionStorage.getItem("theCodeMeshTokens")) || {};
    theCodeMeshTokens[loginKey] = loginToken;
    sessionStorage.setItem("theCodeMeshTokens", JSON.stringify(theCodeMeshTokens));
  }

  const onTypingEnd = () => {
    if (textSequence === 0) {
      setCodeTextVisible(true);
      setCodeVisible(true);
    } else if (textSequence === 1) {
      setCodeTextVisible(false);
      setCodeVisible(false);
      hitRequest();
    } else if (textSequence === 2) {
      setData(tempData);
    } else if (textSequence === 3) {
      stepByStep ? setCodeTextVisible(true) : (window.location = loginUrl);
    } else if (textSequence === 4) {
      setCodeTextVisible(false);
      setCodeVisible(false);
      setData(tempData);
    } else if (textSequence === 5) {
      !stepByStep && setTextSequence(6);
    } else if (textSequence === 6) {
      setCodeTextVisible(true);
      // window.location = "/"
      let startTime = 0;
      const timer = setInterval(() => {
        startTime = startTime + 200;
        if (startTime > waitTime) {
          !stepByStep && setTextSequence(1);
          clearInterval(timer);
        }
      }, 200);
    }
  };

  const testRequest = () => {
    if (scope !== reqScope) {
      //remove existing token
      sessionStorage.removeItem("theCodeMeshTokens");
      scope = reqScope;
    }
    integrationUrl = `${integrationPrefix}${reqEndpoint}`;
    method = reqMethod;
    endpoint = decodeURIComponent(removeStartSlash(reqEndpoint));
    setReqEndpoint(endpoint);
    sessionStorage.setItem("method", method);
    sessionStorage.setItem("scope", scope);
    sessionStorage.setItem("integrationData", JSON.stringify(integrationData));
    sessionStorage.setItem("integrationUrl", integrationUrl);
    sessionStorage.setItem("endpoint", endpoint);
    setData("");
    setTextSequence(1);
  };

  return (
    <React.Fragment>
      <div className="h-screen flex-cols">
        <div className="flex h-1/6 flex-row top-0 px-8 py-4 z-40 w-full backdrop-blur transition-colors duration-500 shadow-lg lg:border-b lg:border-gray-900/10 bg-white" style={{ height: "70px" }}>
          <div>
            <img src={Logo} alt="Logo" style={{ height: "40px" }} />
          </div>
          <div className="text-center w-full font-semibold text-2xl text-gray-600">Playaround</div>
        </div>

        <div className="flex flex-wrap mx-auto px-4 sm:px-6 md:px-8  xs:overflow-y-scroll sm:overflow-y-scroll" style={{ height: "90%" }}>
          <div className="flex-2 flex rounded bg-gray-50 lg:w-4/12 md:w-4/12 xs:w-full sm:w-full overflow-hidden p-4 border-gray-200 shadow-lg h-full tracking-wide break-all">
            <div className="flex-2 w-full overflow-y-scroll">
              {/* <label className="block">
                <span className="text-gray-700">Endpoint</span>
                 <input
                  className="form-input p-2 block w-full border-2 border-blue-600"
                  type="text"
                  name="endpoint"
                  placeholder="endpoint from api reference"
                  onChange={(event) => {
                    setReqEndpoint(event.target.value);
                  }}
                  defaultValue={reqEndpoint}
                /> 
              </label> */}

              <div className="grid grid-cols-2">
                <label className="block mt-2">
                  <span className="text-gray-700">Request Method</span>
                  <select
                    className="p-2 block border-2 border-blue-600 items-center justify-center"
                    defaultValue={reqMethod}
                    onChange={(event) => {
                      setReqMethod(event.target.value);
                    }}
                  >
                    <option>GET</option>
                    <option>POST</option>
                    <option>PATCH</option>
                    <option>PUT</option>
                    <option>DELETE</option>
                  </select>
                </label>

                <label className="block mt-2 items-left">
                  <span className="text-gray-700">Step By Step</span>
                  <input
                    className="form-input h-8 block w-8 border-2 border-blue-600"
                    type="checkbox"
                    name="stepByStep"
                    onClick={() => {
                      console.log(stepByStep);
                      setStepByStep(!stepByStep);
                      window.location.reload();
                    }}
                    checked={stepByStep}
                  />
                </label>
              </div>

              <label className={reqMethod === "POST" || reqMethod === "PATCH" || reqMethod === "PUT" ? "block mt-2" : "hidden"}>
                <span className="text-gray-700">Request Data</span>
                <JSONInput
                  className="p-2 block w-full border-2 border-blue-600"
                  type="text"
                  name="reqData"
                  placeholder={reqData ? reqData : { sampleJson: "value" }}
                  colors={{ default: "#000" }}
                  height="150px"
                  width="100%"
                  theme={"light_mitsuketa_tribute"}
                  style={{ errorMessage: { height: "15px" }, warningBox: { maxHeight: "18px" }, container: { padding: "0.5rem", borderColor: "rgb(37 99 235 / var(--tw-border-opacity))", borderWidth: "2px" } }}
                  confirmGood={true}
                  onChange={(data) => {
                    if (!data.error) {
                      setIntegrationData(JSON.parse(data.json));
                      setJsonError(false);
                    } else {
                      setJsonError(true);
                    }
                  }}
                />
              </label>

              <label className="block mt-2">
                <span className="text-gray-700">Scopes</span>
                <input
                  className="form-input p-2 block w-full border-2 border-blue-600"
                  type="text"
                  name="reqScope"
                  placeholder="Scopes"
                  onChange={(event) => {
                    setReqScope(event.target.value);
                  }}
                  defaultValue={reqScope}
                />
              </label>
              <button className={jsonError && (reqMethod === "POST" || reqMethod === "PATCH" || reqMethod === "PUT") ? "bg-gray-400  mt-4 text-white font-bold py-2 px-4 rounded" : " cursor-pointer bg-blue-600 mt-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"} disabled={jsonError && (reqMethod === "POST" || reqMethod === "PATCH" || reqMethod === "PUT")} onClick={testRequest}>
                Go
              </button>

              {integrationUrl && (
                <div className="pt-4">
                  <div className="font-semibold text-base">Integration url: </div>
                  <div className="pt-1 text-sm">{integrationUrl.split("?")[0]}</div>
                </div>
              )}
              {textSequence === 3 && stepByStep && (
                <div className="pt-2">
                  <div className="font-semibold text-base">Login url : </div>
                  <div className="pt-1 text-sm">{loginUrl.split("&")[0]}</div>
                </div>
              )}
              {(textSequence === 5 || textSequence === 6) && (
                <div className="pt-2">
                  <div className="font-semibold text-base">Token Key : </div>
                  <div className="pt-1 text-sm">{loginKey} </div>

                  <div className="font-semibold text-base">Token Value : </div>
                  <div className="pt-1 text-sm">{loginToken} </div>
                </div>
              )}
            </div>
          </div>
          <div className="h-full bg-gray-200 lg:w-8/12 md:w-8/12 xs:w-full sm:w-full rounded-b md:rounded-b-none md:rounded-r shadow-lg flex-1 flex overflow-hidden">
            <div className="flex-1 overflow-y-scroll text-blue-600">
              {/* Show code prompt and CTA to hit request */}
              {textSequence === 0 && (
                <div className="text-lg font-medium p-8 text-center">
                  <div className="mb-4 text-xl"> Integration url found </div>
                  <div className="flex flex-row justify-center items-center">
                    <div onClick={() => setTextSequence(1)} className="w-40 mr-2 cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                      Send Request
                    </div>
                    {" or "}
                    {stepByStep &&
                      codeTextVisible &&
                      (!codeVisible ? (
                        <div onClick={() => setCodeVisible(true)} className="w-40 ml-2 text-center cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                          Check Code
                        </div>
                      ) : (
                        <div onClick={() => setCodeVisible(false)} className="w-40 ml-2 text-center cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                          Hide Code
                        </div>
                      ))}
                  </div>
                  {stepByStep && codeTextVisible && codeVisible && (
                    <p className="mt-6">
                      <Code code={"sampleRequest"} integrationUrl={integrationUrl} />
                    </p>
                  )}
                </div>
              )}

              {/* Show text that we are sending request*/}
              {textSequence === 1 && (
                <div className="text-lg font-medium p-8 text-center">
                  <TypeWriter typing={1} onTypingEnd={onTypingEnd}>
                    <span className="text-xl">
                      Sending request to your <b> Integration url</b>...
                    </span>
                  </TypeWriter>
                </div>
              )}

              {/* Show data Received*/}
              {textSequence === 2 && (
                <div className="text-lg font-medium p-8 text-center">
                  <TypeWriter typing={1} onTypingEnd={onTypingEnd}>
                    <span className="text-xl ">Received Data</span>
                  </TypeWriter>

                  <div style={{ textAlign: "left", marginTop: "50px" }}>{data && <ReactJson theme={"ashes"} iconStyle={"square"} displayDataTypes={false} src={data} />}</div>
                </div>
              )}

              {/* Handle not authenticated case*/}
              {textSequence === 3 && (
                <div className="flex flex-col justify-center items-center text-sm text-lg font-medium p-8">
                  {!stepByStep ? (
                    <TypeWriter typing={1} onTypingEnd={onTypingEnd}>
                      <div className="text-xl text-semibold">Request not authenticated, redirecting to login url</div>
                    </TypeWriter>
                  ) : (
                    <div className="item-center flex mt-5 flex-col justify-center items-center ">
                      <div className="text-xl text-semibold">Request not authenticated</div>
                      <div onClick={() => (window.location = loginUrl)} className="mt-2 text-center w-40 cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                        Redirect To Login
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Error scenario*/}
              {textSequence === 4 && (
                <div className="text-lg font-medium p-8 text-center">
                  <TypeWriter typing={1} onTypingEnd={onTypingEnd}>
                    <span className="text-xl">Something's not right</span>
                  </TypeWriter>
                  <div className="mt-4"> {data && data}</div>
                </div>
              )}

              {/* Callback token text*/}
              {textSequence === 5 && (
                <div className="text-lg p-8 text-center">
                  <div className="mt-5">
                    <TypeWriter typing={1} onTypingEnd={onTypingEnd}>
                      <span>
                        Received token information, Storing to <b>SessionStorage</b>...
                      </span>
                    </TypeWriter>
                  </div>
                  <small>Use any storage, append tokens as headers in API request</small>
                  {stepByStep && (
                    <div className="flex flex-row justify-center items-center mt-4">
                      <div onClick={() => setTextSequence(6)} className="w-40 mr-2 cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                        Continue
                      </div>
                      {" or "}

                      {codeTextVisible &&
                        (!codeVisible ? (
                          <div onClick={() => setCodeVisible(true)} className="w-40 ml-2 text-center cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                            Check Code
                          </div>
                        ) : (
                          <div onClick={() => setCodeVisible(false)} className="w-40 ml-2 text-center cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                            Hide Code
                          </div>
                        ))}
                    </div>
                  )}
                  {stepByStep && codeTextVisible && codeVisible && (
                    <div className="m-6">
                      <Code code={"sampleStoreToken"} className="m-6" />
                    </div>
                  )}
                </div>
              )}

              {/* Token stored text*/}
              {textSequence === 6 && (
                <div className="text-lg font-medium p-8 text-center">
                  {stepByStep ? (
                    <div className="pb-8">
                      <div className="mb-4 text-xl"> Tokens are stored </div>
                      <div className="flex flex-row justify-center items-center mt-4">
                        <div onClick={() => setTextSequence(1)} className="w-40 mr-2 cursor-pointer border-2 border-blue-600 rounded px-4 py-2 text-sm hover:bg-gray-100 bg-white">
                          Continue
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <TypeWriter typing={1} onTypingEnd={onTypingEnd}>
                        <span className="text-xl text-semibold">Tokens are stored...</span>
                      </TypeWriter>
                      {!codeTextVisible && (
                        <div>
                          <small>click anywhere and hold to wait</small>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Workflow;
