import React from "react";
import Editor from "@monaco-editor/react";

function Code(props) {
  const sampleRequestCode = () => {
    switch (props.code) {
      case "sampleRequest":
        return `        // update redirect_url
        const request_url='${props.integrationUrl}'
        
        //Request Method : any of these [GET, POST, PUT, PATCH, DELETE]
        const request_method = 'GET' 
        const payload = '' // if any

        //Both key and loginToken are passed to redirect url configured in project settings
        const headers ={<loginKey>:<loginToken>};
        
        axios.request(url=request_url, method=request_method, payload=payload, headers=headers).then(response => {
            //use data
            console.log(response)
        }).catch(err => {
            // handle generic errors
            // handle 401/400, redirect to login url
            let loginUrl = error.response?.data?.message?.loginUrl;
            window.open(loginUrl, '_blank');
        })`;
      case "sampleStoreToken":
        return `      let loginKey = query.get("loginKey");
        let loginToken = query.get("loginToken");
        // Checks if we have loginToken and Window object
        if (loginToken && loginKey && window && sessionStorage) {
          const theCodeMeshTokens = JSON.parse(sessionStorage.getItem("theCodeMeshTokens")) || {};
          theCodeMeshTokens[loginKey] = loginToken;
          sessionStorage.setItem("theCodeMeshTokens", JSON.stringify(theCodeMeshTokens));
        }`;
      default:
        return "";
    }
  };
  return (
    <React.Fragment>
      <div className="flex justify-center mt-2">
        <Editor
          height="60vh"
          defaultLanguage="javascript"
          loading="Loading..."
          options={{
            readOnly: true,
            scrollBeyondLastLine: false,
            hideCursorInOverviewRuler: true,
            selectOnLineNumbers: false,
            renderControlCharacters: false,
            lineNumbers: "off",
            glyphMargin: false,
            folding: false,
            // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            overviewRulerLanes: 0,
            scrollbar: {
              vertical: "hidden",
            },
            minimap: { enabled: false },
            renderLineHighlight: "none",
            overviewRulerBorder: false,
          }}
          defaultValue={sampleRequestCode()}
        />
      </div>
    </React.Fragment>
  );
}
export default Code;
